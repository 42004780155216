'use strict';

angular.module('enervexSalesappApp').controller('TodolistsCtrl', function($scope, $filter, Account, Job, $stateParams, TodolistsService, _, Util) {
	$scope.jobLabel = Util.jobLabel;
	$scope.accountId = $stateParams.accountId;
	$scope.jobId = $stateParams.jobId;
	$scope.account = Account.get({
		id: $stateParams.accountId
	});
	TodolistsService.getJob().$promise.then(function(job){
		$scope.job = job;
		$scope.members = [];
		_.each(job.members, function(member){
			$scope.members.push(member.user);
		});
	});
	fetchTodoLists();
	function fetchTodoLists(){
		TodolistsService.getTodoLists().$promise.then(function(todolists){
			$scope.todolists = todolists;
			_.each(todolists, function(todolist){
				TodolistsService.getAllTodos(todolist).$promise.then(function(todos){
					_.each(todos, function(todo){
						var dueDate = new Date(todo.assignAt);
						if (todo.assignAt){
							todo.assignAt = dueDate;
							dueDate = $filter('date')(dueDate, 'dd/MM/yyyy');
						}
					})
					todolist.todos = todos;
				});
			});
		});
	}
	$scope.removeTodoList = function(todoList) {
		TodolistsService.deleteTodoList(todoList).$promise.then(function(res){
			fetchTodoLists();
		});
	}
	$scope.todoComplete = function(todo){
		if (todo.status === 'open') {
			todo.status = 'closed';
		} else {
			todo.status = 'open';
		}
		TodolistsService.updateTodo(todo).$promise.then(function(res){
			fetchTodoLists();
		});
	}
	$scope.todoChange = function (todo){
		TodolistsService.updateTodo(todo);
	}
});
